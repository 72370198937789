import { createContext, useContext, useState } from "react";
export const ALERTTYPE = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    INFO: "INFO"
}
const AlertContext = createContext();

export function AlertContextProvider({ children }) {
    const [isShowing, setIsShowing] = useState(false);
    const [message, setMessage] = useState("Something Went Wrong!");
    const [type, setType] = useState(ALERTTYPE.ERROR);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    function open(message, type = ALERTTYPE.INFO) {
        setIsShowing(true);
        setMessage(message);
        setType(type);
    }
    function close() {
        setIsShowing(false);
        setMessage("Something Went Wrong!")
        setType(ALERTTYPE.ERROR)
    }
    const state = {
        isShowing,
        open,
        close
    }
    return (
        <AlertContext.Provider value={state}>
            {children}
            {isShowing && <div className="bg-black/75 fixed inset-0 flex justify-center">
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {/* <button type="button" onClick={close} className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button> */}
                        <div className="p-4 md:p-5 text-center">
                            <div className={type === ALERTTYPE.ERROR ? "text-red-600" : (type === ALERTTYPE.SUCCESS ? "text-teal-600" : "text-blue-600")}>
                                {type === ALERTTYPE.INFO && <svg className="mx-auto mb-4 w-12 h-12" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>}
                                {type ===ALERTTYPE.ERROR && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mx-auto mb-4 w-12 h-12">
                                    <path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                                </svg>}
                                {type ===ALERTTYPE.SUCCESS && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mx-auto mb-4 w-12 h-12">
                                    <path fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                                </svg>}


                            </div>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{message}</h3>
                            <button onClick={close} data-modal-hide="popup-modal" type="button"
                                className={
                                    classNames(
                                        "focus:ring-4 focus:outline-none font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center",
                                        type === ALERTTYPE.ERROR ? "text-white bg-red-600 hover:bg-red-800 focus:ring-red-300 dark:focus:ring-red-800"
                                            : (type === ALERTTYPE.SUCCESS ? "text-white bg-teal-600 hover:bg-teal-800 focus:ring-teal-300 dark:focus:ring-teal-800"
                                                : "text-white bg-blue-600 hover:bg-blue-800 focus:ring-blue-300 dark:focus:ring-blue-800")
                                    )

                                }>
                                {type === ALERTTYPE.ERROR ? "Close" : (type === ALERTTYPE.SUCCESS ? "OK,Got It" : "Got It")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
        </AlertContext.Provider>
    )
}

export default function useAlert(){
    return useContext(AlertContext);
}