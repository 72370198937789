import './App.css';
import { useEffect, useState } from 'react';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react'
import * as ethers from "ethers"
import useAlert, { ALERTTYPE } from './context/AlertContext';
// 1. Get projectId
const projectId = 'a22a721f8273c147f358e8b08689a47a';
const ContractAddress = "0x12Ecb3Ce564E695Ee7dd842494A74A5aFEBC3943";
// 2. Set chains
const mainnet = {
  chainId: 137,
  name: 'Polygon Mainnet',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.io',
  rpcUrl: 'https://falling-solemn-lake.matic.quiknode.pro/c22b7afe7ee110e86d69a9f0de40183ceadc1838'
}

// 3. Create a metadata object
const metadata = {
  name: 'DEAR Protocol',
  description: 'Airdrop Claim Site',
  url: 'https://airdrop.dearprotocol.com', // origin must match your domain & subdomain
  icons: ['https://www.dearprotocol.com/icon-200.png']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: 'https://falling-solemn-lake.matic.quiknode.pro/c22b7afe7ee110e86d69a9f0de40183ceadc1838', // used for the Coinbase SDK
  defaultChainId: 137 // used for the Coinbase SDK
})

const AirdropABI = [
  "function claim(address to,uint256 nonce,bytes memory signature) public",
  "function isClaimed(address to) public view returns(bool)"
]
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  allowUnsupportedChain: false,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

function App() {
  const [TxProgress, setTxProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isWinner, setIsWinner] = useState(false);
  const [isParticipated, setIsParticipated] = useState(false);
  const [signature, setSignature] = useState("0x");

  const { open } = useWeb3Modal()
  const alert_ = useAlert();
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  async function fetchContractInfo() {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const contract = new ethers.Contract(ContractAddress, AirdropABI, provider)
    let isRewardClaimed = await contract.isClaimed(address);
    setIsClaimed(isRewardClaimed);
  }
  useEffect(() => {
    if (address) {
      setIsLoading(true);
      // console.log("Address:", address);
      fetchContractInfo();
      // Fetch details from db
      fetch("https://www.dearprotocol.com/api/airdrop/get-info-by-address/" + ethers.utils.getAddress(address)).then(res => res.json()).then(res => {
        // console.log(res);
        if (res.success) {
          if (res.data.registered) {
            setIsParticipated(true);
            setIsLoading(false);
            if (res.data.entry.isAwarded) {
              setIsWinner(true);
              setSignature(res.data.entry.signature);
            }
          } else {
            setIsLoading(false);
            setIsParticipated(false);
          }
        } else {
          console.log(res)
          setIsLoading(false);
        }
      }).catch(err => {
        console.log(err);
        setIsLoading(false);
      })
    }
  }, [address])
  const ClaimAirdrop = async () => {
    // BrowserProvider
    if (chainId === mainnet.chainId) {
      // console.log("Here");
      try {
        setIsLoading(true);
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = provider.getSigner();
        // const signature = await signer?.signMessage('Hello AppKit Ethers')
        const contract = new ethers.Contract(ContractAddress, AirdropABI, signer)
        // console.log(signature)
        // let signature = "0x24f766ecaa7f8aeae946f0d3273b34ac6760645f0fcf9f478445ca0dae6512633f949bb4aef477705ebae64ed5feada87a4699b76196d40663b5338b437007c21b"
        // let isClaimed = await contract.isClaimed(address);
        contract.estimateGas.claim(address, 0, signature).then((gas)=>{
          // 
          // console.log(gas.toNumber());
          // setIsLoading(false);
          contract.claim(address, 0, signature).then((res)=>{
            setIsLoading(false);
            console.log(res);
            // alert_.open("Airdrop",ALERTTYPE.ERROR);
            // alert them that its claimed
            setTxProgress(true)
            res.wait().then(resp=>{
              setTxProgress(false)
              // console.log(resp);
              setIsClaimed(true);
              fetchContractInfo();
              alert_.open("Airdrop Claimed!",ALERTTYPE.SUCCESS);
            }).catch(err=>{
              setTxProgress(false)
            })
          }).catch(err=>{
            setIsLoading(false);
            console.log(err.toString(),err);
            if (err.code === 4001) {
              // 
            } else if (err.code === "UNPREDICTABLE_GAS_LIMIT") {
              console.log(err.toString());
              alert_.open("Something bad happened!",ALERTTYPE.ERROR);
            }else{
              alert_.open("Something bad happened!",ALERTTYPE.ERROR);
            }
          })
        }).catch(err=>{
          setIsLoading(false);
          // console.log(err.toString(),err);
          if (err.code === 4001) {
            // 
          } else if (err.code === "UNPREDICTABLE_GAS_LIMIT") {
            // console.log(err.toString(),err["data"]);
            alert_.open("Transaction expected to fail",ALERTTYPE.ERROR);
          }else{
            alert_.open("Transaction expected to fail",ALERTTYPE.ERROR);
          }
        })
      } catch (err) {
        // Alert
        // alert(err);
        // console.log(typeof (err.code), err.code);
        if (err.code === 4001) {
          // 
        } else if (err.code === "UNPREDICTABLE_GAS_LIMIT") {
          // console.log(err.toString());
          alert_.open("Something Bad Happened!",ALERTTYPE.ERROR);
        }
      }
    } else {
      // alert("Connect to " + mainnet.name + " Network");
      open({ view: "Networks" })
    }
  }
  return (
    <>
      <div className="App">
        <div className="flex justify-center py-10">
          <img src="/airdrop-logo.svg" className='h-10' />
        </div>
        <div className="py-5">
          <h1 className="text-2xl font-bold">Claim your Airdrop</h1>
        </div>
        <div className="w-full max-w-3xl px-5 mx-auto py-5">
          <p>Connect your wallet to see if you are eligible to claim or not. As token is deployed in polygon chain you must connect in polygon chain.</p>
        </div>

        {/* {address} */}

        {isConnected && <button onClick={() => open()}>
            <p className="bg-gray-200 px-10 py-3 my-5 w-fit mx-auto rounded-xl">{address}</p>
          </button>
        }
        <div>
          {!isConnected ? <button className="bg-indigo-600 text-white border border-white px-16 py-3 rounded-xl font-bold" onClick={() => { open() }}>Connect Wallet</button> :
            (isParticipated ? (isWinner ? (!isClaimed ? <button
              onClick={ClaimAirdrop}
              className="bg-indigo-600 text-white border border-white px-16 py-3 rounded-xl font-bold">
              Claim Airdrop
            </button> : <button
              className="cursor-not-allowed bg-indigo-300 text-white border border-white px-16 py-3 rounded-xl font-bold">
              Already Claimed!
            </button>) : <button
              className="cursor-not-allowed bg-indigo-300 text-white border border-white px-16 py-3 rounded-xl font-bold">
              Not an Winner
            </button>) : <button
              className="cursor-not-allowed bg-indigo-300 text-white border border-white px-16 py-3 rounded-xl font-bold">
              Not Participated
            </button>)
          }
        </div>
      </div>
      {isLoading && <div className="fixed inset-0 bg-white flex justify-center items-center">
        <div role="status">
          <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>}
      {TxProgress && <div className='absolute top-0 right-0 mr-5 mt-5 bg-blue-100 shadow-xl border-b-4 border-blue-600 px-10 py-3 flex items-center'>
        <div role="status" className="mr-5">
          <svg aria-hidden="true" className="inline w-7 h-7 text-blue-600 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          <span className="sr-only">Loading...</span>
        </div> <p>Transaction In Progress</p>
      </div>}
    </>
  );
}

export default App;
